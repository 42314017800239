import React from 'react';
import { Button } from 'react-bootstrap';
import Section from 'components/common/Section';
import { Helmet } from 'react-helmet-async';

const Error404 = () => {
  return (
    <>
      <Helmet>
        <title>ScanBox</title>
        <meta
          name="description"
          content="Ein benutzerfreundliches Tool unterstützt IT-Administratoren bei der effizienten Sicherheitsanalyse"
        />
      </Helmet>
      <Section className="bg-body-tertiary  flex-grow-1 text-center pt-10 ">
        <div className=" pt-8">
          <div className="display-1 text-300 fs-error">404</div>
          <p className="lead mt-4 text-800 font-sans-serif fw-semibold">
            Seite nicht gefunden
          </p>
          <a href="/">
            <Button
              variant="outline-primary"
              size="lg"
              className=" me-auto align-items-start rounded-pill btn-outline-scanbox  mt-4"
            >
              Zur Startseite
            </Button>
          </a>
        </div>
      </Section>
    </>
  );
};

export default Error404;
