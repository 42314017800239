import React from 'react';
import processList from 'data/feature/processList';
import Section from 'components/common/Section';
import Process from './Process';
import SectionHeader from './SectionHeader';
import { isIterableArray } from 'helpers/utils';

const Processes = () => (
  <Section id="Features" className=" bg-body-tertiary  pb-0 pt-3 mt-2">
    <SectionHeader title="ScanBox-Features" subtitle="" />
    {isIterableArray(processList) &&
      processList.map((process, index) => (
        <Process
          key={index}
          isFirst={index === 0}
          reverse={index % 2 === 0}
          {...process}
        />
      ))}
  </Section>
);

export default Processes;
