import illustration3 from 'assets/img/services/risk.png';
import illustration8 from 'assets/img/services/security4.png';
import illustration1 from 'assets/img/services/a1.png';

export default [
  {
    media: { icon: ['fas', 'user-check'], color: 'primary', className: 'fs-5' },
    title: 'Einfaches Management von Agenten ',
    description:
      'Zentrale Verwaltung von Agenten mit plattformübergreifender Unterstützung. Minimaler Installationsaufwand mit optimierten Kommandozeilenbefehlen für IT-Administratoren.',
    image: illustration1,
  },
  {
    media: { icon: ['fas', 'chart-line'], color: 'primary', className: 'fs-5' },
    title: 'Simples Sicherheits-Analysetool für IT-Administratoren',
    description:
      'Ein benutzerfreundliches Tool, das IT-Administratoren dabei unterstützt, Sicherheitsanalysen effizient und effektiv durchzuführen.',
    image: illustration8,
  },
  {
    media: { icon: ['fa', 'user-shield'], color: 'primary', className: 'fs-5' },
    title: 'Erkennung auffälliger Benutzer und Assets',
    description:
      'Simples Scoring-System mit historischem Verlauf zur Identifizierung und Überwachung von ungewöhnlichem Verhalten von Benutzern und Assets',
    image: illustration3,
  },
];
