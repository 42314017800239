import React, { useState } from 'react';
import { Button, Row, Col, Form, Alert } from 'react-bootstrap';
import InputField from '../user/InputField';
import Section from 'components/common/Section';
import SectionHeader from './SectionHeader';

const KontactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({ show: false, message: '', variant: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.email) newErrors.email = 'Email is required';
    if (!formData.message) newErrors.message = 'Message is required';
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const response = await fetch(
        'https://scanbox-product.de/contact_form_mail/send_mail.php',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log(result);
        setAlert({
          show: true,
          message: 'Message sent successfully!',
          variant: 'success',
        });
        setFormData({ name: '', email: '', message: '' });
        setErrors({});
      } else {
        console.error('Error:', response.statusText);
        setAlert({
          show: true,
          message: 'Failed to send message. Please try again.',
          variant: 'danger',
        });
      }
    } catch (error) {
      console.error('Error:', error);
      setAlert({
        show: true,
        message: 'Failed to send message. Please try again.',
        variant: 'danger',
      });
    }
  };

  return (
    <Section id="Kontaktform" className="pb-8">
      <SectionHeader
        title="Kontaktieren Sie uns!
        "
        subtitle="Schreiben Sie uns eine E-Mail und wir setzen uns umgehend mit Ihnen
        in Verbindung."
      />

      <Row className="justify-content-center">
        <Col md={7} lg={7} xl={7} className="mx-auto">
          {alert.show && (
            <Alert
              variant={alert.variant}
              onClose={() => setAlert({ show: false })}
              dismissible
            >
              {alert.message}
            </Alert>
          )}
          {/*
            <img
              className="bg-auth-circle-shape"
              src={bg3}
              alt=""
              width="180"
            />
            <img
              className="bg-auth-circle-shape-2"
              src={bg3}
              alt=""
              width="150"
            />
            
          */}

          <Form onSubmit={handleSubmit}>
            <InputField
              value={formData.name}
              label="Name"
              name="name"
              handleChange={handleChange}
              isInvalid={!!errors.name}
              errorMessage={errors.name}
            />
            <InputField
              value={formData.email}
              label="E-Mail"
              name="email"
              handleChange={handleChange}
              isInvalid={!!errors.email}
              errorMessage={errors.email}
            />
            <InputField
              value={formData.message}
              label="Nachricht"
              name="message"
              handleChange={handleChange}
              as="textarea"
              rows={3}
              isInvalid={!!errors.message}
              errorMessage={errors.message}
            />

            <Form.Group as={Row} className="pt-3">
              <Col sm={{ span: 10, offset: 3 }}>
                <Button
                  type="submit"
                  variant="outline-primary"
                  size="lg"
                  className="border-2 rounded-pill mt-0 fs-9 py-1 mb-5  px-4 btn-outline-scanbox"
                >
                  Absenden
                </Button>
              </Col>
            </Form.Group>
          </Form>
        </Col>
        <Col md={5} lg={5} xl={5} className="d-flex">
          <div className=" mx-sm-0 mx-md-auto mx-lg-auto mx-auto mb-2">
            <h3 className="fs-6 text-700">Kontaktdaten</h3>
            <p className="lh-lg">
              DECOIT® GmbH & Co. KG
              <br />
              Fahrenheitstraße 9<br />
              28359 Bremen
              <br />
              <strong>Phone:</strong> +49 (0) 421 - 59 60 64-0
              <br />
              <strong>Fax:</strong> +49 (0) 421 - 59 60 64-09
              <br />
              <strong>E-Mail:</strong>{' '}
              <a href="mailto:info@decoit.de">info@decoit.de</a>
            </p>
          </div>
        </Col>
      </Row>
    </Section>
  );
};

export default KontactForm;
