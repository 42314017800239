import React from 'react';
import Section from 'components/common/Section';
import { Helmet } from 'react-helmet-async';

const Impressum = () => {
  return (
    <>
      <Helmet>
        <title>ScanBox</title>
        <meta
          name="description"
          content="Ein benutzerfreundliches Tool unterstützt IT-Administratoren bei der effizienten Sicherheitsanalyse"
        />
      </Helmet>
      <Section className="bg-body-tertiary flex-grow-1  pt-5">
        <div className="px-3 ">
          <h2 className=" mt-5 pt-4 text-header fw-semibold pb-2 mb-4">
            Impressum
          </h2>
          <p className="text-600 fs-8 pb-2 d-flex ">
            <div className="me-lg-5 me-xl-5 me-0 pe-5">
              DECOIT® GmbH & Co. KG
              <br />
              Fahrenheitstraße 9<br />
              28359 Bremen
              <br />
            </div>
            <div className="ms-2 ps-2">
              <strong>Phone:</strong> +49 (0) 421 - 59 60 64-0
              <br />
              <strong>Fax:</strong> +49 (0) 421 - 59 60 64-09
              <br />
              <strong>E-Mail:</strong>{' '}
              <a href="mailto:datenschutz@decoit.de">datenschutz@decoit.de</a>
            </div>
          </p>

          <h3 className="text-header pb-4 mb-2 pt-3">
            Die DECOIT® GmbH & Co. KG wird vertreten durch:
          </h3>
          <table className="custom-table text-600 fs-8 ">
            <tbody>
              <tr className="pb-2 mb-1">
                <td className="me-lg-5 me-xl-5 me-0  pe-2 ">
                  Persönlich haftende Gesellschafterin:
                </td>
                <td>
                  DECOIT Verwaltungs GmbH
                  <br />
                  Amtsgericht Bremen
                  <br />
                  HRB 38688 HB
                </td>
              </tr>
              <tr className="pb-2 mb-2">
                <td>Geschäftsführer:</td>
                <td>Prof. Dr. Kai-Oliver Detken</td>
              </tr>
              <tr className="pb-2 mb-2">
                <td>Amtsgericht Bremen:</td>
                <td>HRA 29788 HB</td>
              </tr>
              <tr className="pb-1">
                <td>USt-IdNr:</td>
                <td>DE228287472</td>
              </tr>
            </tbody>
          </table>

          {/*<div className='mt-3 pt-3'>
                  <h3 className=" text-header pb-1 pt-1 ">Design, Text, Grafik und Software - © DECOIT® GmbH & Co. KG:
                  </h3>
                  <p className='text-600 fs-8 pb-2'>
                      Kopieren oder Reproduktion oder jede andere Art der Wiedergabe oder Verwendung der in diesem Internet-Auftritt verfügbaren Materialien, bzw. Informationen, nur mit schriftlicher Genehmigung der DECOIT® GmbH & Co. KG. Für Inhalte Dritter, insbesondere die aus Weiterleitungen zu anderen Anbietern von Inhalten resultieren, übernimmt die DECOIT® GmbH & Co. KG keine Verantwortung oder Haftung.
                  </p>
                  <h4 className='text-700 pb-2'>Dezidierter Bildnachweis
                  </h4>

                  <p className='text-600 fs-8 pb-2'>
                      Unter dezidierter Bildnachweis finden Sie eine Übersicht aller auf der Website verwendeten lizensierten Bilder mit Nennung des Urhebers und der jeweiligen Quelle.
                  </p>

                  <h4 className='text-700 pb-2'>Redaktionell Verantwortlicher nach § 18 Abs. 2 MStV:

                  </h4>

                  <p className='text-600 fs-8 pb-2 d-flex '>
                      <div className='me-5 pe-5'>
                          Prof. Dr. Kai-Oliver Detken<br/>
                          Fahrenheitstraße 9<br/>
                          28359 Bremen<br/>
                      </div>
                  </p>

              </div>

              <div>
                  <h3 className=" text-header pb-1 pt-1 ">Technische Voraussetzungen:
                  </h3>
                  <p className='text-600 fs-8 pb-2'>
                  Für eine optimale Darstellung sollte Javascript aktiviert sein. Zum Betrachten einiger Inhalte benötigen Sie zusätzliche Software wie zum Beispiel den Acrobat Reader von Adobe, den Flash Player von Macromedia oder den Real-Player.                </p>
                

              </div>*/}
        </div>
      </Section>
    </>
  );
};

export default Impressum;
