import React, { useState } from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { Card } from 'react-bootstrap';
import Hoverbox from 'components/common/Hoverbox';

library.add(fas);

const CardService = ({ media, title, description, image }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Card
      className="card-span h-100"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="card-span-img">
        <FontAwesomeIcon
          icon={media.icon}
          className={className(
            { [`text-${media.color}`]: media.color },
            media.className
          )}
        />
      </div>

      <Hoverbox className="text-center">
        <Card.Body
          className={className('pt-6 pb-4', {
            'card-body-hover-content': isHovered,
          })}
        >
          <div>
            <h3 className="mb-1 pb-1  fs-xs-8 fs-sm-7  fs-md-6   fs-lg-8 fs-xl-7 fs-xxl-6 fw-medium text-700 ">
              {title}
            </h3>

            <p className=" fs-sm-9  fs-md-8 fs-lg-8 fs-xl-8 fs-xxl-7">
              {description}
            </p>
          </div>
        </Card.Body>

        <Hoverbox.Content className=" flex-center">
          <img
            className="img-fluid bg-white py-2 px-4 "
            src={image}
            alt={title}
          />
        </Hoverbox.Content>
      </Hoverbox>
    </Card>
  );
};

CardService.propTypes = {
  media: PropTypes.shape({
    icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
    color: PropTypes.string.isRequired,
    className: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  children: PropTypes.node,
  image: PropTypes.string.isRequired,
};

export default CardService;
