import { Navbar, Nav, Button } from 'react-bootstrap';
import Logo from 'components/common/Logo';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { sectionIds } from './sectionIds';

const NavbarTop = () => {
  const [activeLink, setActiveLink] = useState('Startseite');
  const [scrolled, setScrolled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = (sectionId) => {
    setActiveLink(sectionId);
    if (location.pathname === '/') {
      const element = document.getElementById(sectionId);
      if (element) {
        const yOffset = -200;
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    } else {
      navigate(`/#${sectionId}`);
    }
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }

    sectionIds.forEach((sectionId) => {
      const element = document.getElementById(sectionId);
      if (element) {
        const rect = element.getBoundingClientRect();
        if (rect.top <= 500 && rect.bottom >= 500) {
          setActiveLink(sectionId);
        }
      }
    });
  };

  useEffect(() => {
    if (location.pathname !== '/') {
      setActiveLink('');
    } else {
      setActiveLink('Startseite');
    }
  }, [location.pathname]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Navbar
      className={`mb-3  navbar ${scrolled ? 'navbar-scrolled' : 'bg-white'}`}
      expand="lg"
      fixed="top"
    >
      <Navbar.Brand href="/">
        <Logo width={150} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ms-auto align-items-lg-center fs-8 mt-1 menu-bar">
          {sectionIds.map((sectionId, i) => (
            <Nav.Item key={i}>
              <Nav.Link
                as="button"
                onClick={() => handleNavigation(sectionId)}
                className={`nav-link ${
                  activeLink === sectionId ? 'active' : ''
                }`}
              >
                {sectionId}
              </Nav.Link>
            </Nav.Item>
          ))}
          {/*<a href="https://decoit.de/de/kontaktformular.html" target="_blank" rel="noopener noreferrer"> */}
          <a rel="noopener noreferrer">
            <Button
              variant="outline-primary"
              size="lg"
              className=" me-auto align-items-start rounded-pill btn-outline-scanbox   mt-xs-3 mt-sm-3 mt-lg-1 "
              onClick={() => handleNavigation('Kontaktform')}
            >
              Kontakt
            </Button>
          </a>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarTop;
