import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { menuList2 } from 'data/footer';

const FooterList = () => (
  <ul className="list-unstyled d-flex text-600  fw-medium  me-0">
    <li className="me-2  ms-2 ps-4 ps-sm-4">
      <Link className="link-600" to="https://decoit.de/de/home.html">
        DECOIT
      </Link>
    </li>
    <li className="mx-2 px-1">
      <Link className="link-600" to="/Agb">
        AGB
      </Link>
    </li>
    <li className="mx-2 px-1">
      <Link className="link-600" to="/Impressum">
        Impressum
      </Link>
    </li>
    <li className="mx-2 px-1 pe-5">
      <Link className="link-600" to="/Datenschutz">
        Datenschutz
      </Link>
    </li>
  </ul>
);

FooterList.propTypes = { list: PropTypes.array.isRequired };

const FooterStandard = () => {
  const currentYear = new Date().getFullYear();

  return (
    <section className="bg-white  fs-10 pb-1 pt-3 footer">
      <Row>
        <Col xl={6} lg={6} md={12} sm={12} className="mt-2 ms-0 ps-0 pe-0 me-0">
          <p className="mb-0 text-600  fw-medium ps-5 ms-1">
            &copy; Copyright {currentYear}.{' '}
            <a
              className="text-600 "
              href="https://decoit.de/de/home.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              DECOIT
            </a>{' '}
            GmbH & Co. KG. All rights reserved.{' '}
          </p>
        </Col>

        <Col
          xl={6}
          md={12}
          sm={12}
          className="mt-2 d-flex justify-content-lg-end justify-content-start me-0 pe-0"
        >
          <FooterList list={menuList2} />
        </Col>
      </Row>
    </section>
  );
};

export default FooterStandard;
