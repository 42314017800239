import React from 'react';
import Section from 'components/common/Section';
import { Helmet } from 'react-helmet-async';
const Agb = () => (
  <>
    <Helmet>
      <title>ScanBox</title>
      <meta
        name="description"
        content="Ein benutzerfreundliches Tool unterstützt IT-Administratoren bei der effizienten Sicherheitsanalyse"
      />
    </Helmet>
    <Section className="bg-body-tertiary px-2 mt-md-5 mt-lg-1 mt-xl-1 mt-5">
      <div className="mx-3 px-3">
        <h2 className="text-header fw-semibold pb-4 mb-xl-4 mb-lg-4 mb-0 pt-sm-2">
          {' '}
          Unsere Allgemeinen Geschäftsbedingungen
        </h2>
        <ul className="list-unstyled agb-list py-2 ">
          <li className="list-group-item mb-3 pb-2">
            <h3 className=" text-header ">
              Geltungsbereich und Ausschließlichkeit
            </h3>
            <p className="mb-1 text-justify">
              Diese Geschäftsbedingungen gelten für alle Vertragsbeziehungen und
              vorvertraglichen Verhandlungen mit unseren Kunden, unabhängig von
              Art und Umfang der Leistung im Rahmen laufender und zukünftiger
              Geschäftsverbindungen. Es gelten ausschließlich unsere
              Geschäftsbedingungen: Entgegenstehende Geschäftsbedingungen
              unserer Vertragspartner oder Dritter sind nur gültig, wenn die
              DECOIT GmbH & Co. KG ausdrücklich und schriftlich ihrer Geltung
              zustimmt. Wenn Sie damit nicht einverstanden sein sollten, weisen
              Sie uns sofort schriftlich darauf hin. Für diesen Fall müssen wir
              uns vorbehalten, unsere Angebote zurückzuziehen, ohne dass uns
              gegenüber Ansprüche irgendwelcher Art erhoben werden können. Dem
              formularmäßigen Hinweis auf eigene Geschäftsbedingungen
              widersprechen wir hiermit ausdrücklich.
            </p>
          </li>
          <li className="list-group-item mb-3 pb-2">
            <h3 className="text-header">Vertragsschluss und Schriftform</h3>
            <p className="mb-1 text-justify">
              {' '}
              Diese Geschäftsbedingungen gelten für alle Vertragsbeziehungen und
              vorvertraglichen Verhandlungen mit unseren Kunden, unabhängig von
              Art und Umfang der Leistung im Rahmen laufender und zukünftiger
              Geschäftsverbindungen. Es gelten ausschließlich unsere
              Geschäftsbedingungen: Entgegenstehende Geschäftsbedingungen
              unserer Vertragspartner oder Dritter sind nur gültig, wenn die
              DECOIT GmbH & Co. KG ausdrücklich und schriftlich ihrer Geltung
              zustimmt. Wenn Sie damit nicht einverstanden sein sollten, weisen
              Sie uns sofort schriftlich darauf hin. Für diesen Fall müssen wir
              uns vorbehalten, unsere Angebote zurückzuziehen, ohne dass uns
              gegenüber Ansprüche irgendwelcher Art erhoben werden können. Dem
              formularmäßigen Hinweis auf eigene Geschäftsbedingungen
              widersprechen wir hiermit ausdrücklich.
            </p>
          </li>
          <li className="list-group-item mb-3 pb-2">
            <h3 className="text-header"> Zahlungsbedingungen und Preise</h3>
            <p className="mb-1 text-justify">
              Eine vertragliche Verpflichtung gehen wir grundsätzlich nur ein,
              wenn Art und Umfang von Leistung und Gegenleistung von beiden
              Seiten schriftlich festgelegt worden sind. Spätere mündliche
              Änderungen und Ergänzungen werden erst wirksam, wenn sie danach
              schriftlich bestätigt worden sind. Das Gleiche gilt für alle
              Willenserklärungen, insbesondere Beanstandungen, Mahnungen und
              Mängelrügen im Rahmen der Vertragsbeziehungen. Vertragsstrafen
              müssen sich beide Vertragsparteien schriftlich vorbehalten. Diese
              Klausel kann nur durch ausdrückliche schriftliche Vereinbarung
              aufgehoben werden.
            </p>
          </li>

          <li className="list-group-item mb-3 pb-2">
            <h3 className="text-header"> Lieferung und Versand</h3>
            <p className="mb-1 text-justify">
              Alle von uns genannten Liefertermine sind unverbindliche
              Liefertermine, es sei denn, dass ein Liefertermin ausdrücklich
              schriftlich bindend vereinbart wird. Verlangt der Käufer nach
              Auftragserteilung Änderungen oder Ergänzungen des Auftrages oder
              treten sonstige Umstände ein, die uns eine Einhaltung des
              Liefertermins unmöglich machen, obwohl wir diese Umstände nicht zu
              vertreten haben, so verschiebt sich der Liefertermin um einen
              angemessenen Zeitraum. Wird die DECOIT GmbH & Co. KG an der
              rechtzeitigen Vertragserfüllung, z. B. durch Beschaffungs-,
              Fabrikations- oder Lieferstörungen bei ihr oder bei ihren
              Zulieferanten gehindert, so gelten die allgemeinen
              Rechtsgrundsätze mit der Maßgabe, dass der Kunde nach Ablauf von
              einem Monat eine Nachfrist von sechs Wochen setzen kann. Ist die
              Nichteinhaltung eines verbindlichen Liefertermins nachweislich auf
              Mobilmachung, Krieg, Aufruhr, Streik oder Aussperrung oder auf
              sonstige nach allgemeinen Rechtsgrundsätzen von uns nicht zu
              vertretende Umstände zurückzuführen, so wird die Lieferfrist
              angemessen verlängert. Der Kunde kann vom Vertrag zurücktreten,
              wenn er uns nach Ablauf der verlängerten Frist eine angemessene
              Nachfrist setzt. Der Rücktritt hat schriftlich zu erfolgen, wenn
              wir nicht innerhalb der Nachfrist die Lieferung erfüllen. Wird der
              DECOIT GmbH & Co. KG die Vertragserfüllung aus den vorgenannten
              Gründen ganz oder teilweise unmöglich, so sind wir von der
              Lieferpflicht befreit. Die Kosten für den Versand und die
              Transportversicherung sind grundsätzlich vom Kunden zu tragen,
              wobei die Wahl des Versandweges und der Versandart in unserem
              freien Ermessen liegt. Der Kunde ist verpflichtet, die Ware beim
              Eintreffen sofort zu untersuchen und erkennbare Transportschäden
              sowie jegliche Beschädigung der Verpackung unverzüglich
              schriftlich uns zu melden. Gleiches gilt für verdeckte Schäden.
              Geht die DECOIT GmbH & Co. KG aufgrund des Unterlassens dieser
              Verpflichtung ihrer Ansprüche gegenüber der Versicherung oder dem
              Sublieferanten verlustig, so haftet der Kunde für sämtliche
              Kosten, die aus dieser Obliegenheitsverletzung resultieren. Die
              Gefahr geht auf den Kunden über, sobald die Ware uns verlässt.
            </p>
          </li>

          <li className="list-group-item mb-3 pb-2">
            <h3 className="text-header"> Eigentumsvorbehalt</h3>
            <p className="mb-1 text-justify">
              Gelieferte Hardware oder Software bleibt bis zur vollständigen
              Bezahlung sämtlicher Forderungen der DECOIT GmbH & Co. KG aus der
              Geschäftsverbindung mit dem Kunden in Haupt- und Nebensache
              Eigentum von uns. Der Kunde ist verpflichtet, die unter dem
              Eigentumsvorbehalt der DECOIT GmbH & Co. KG stehenden Sachen
              ordnungsgemäß zu versichern (d. h. Diebstahl-, Feuer-, Wasser- und
              Schwachstromversicherung) und uns auf Anforderung eine solche
              Versicherung nachzuweisen. Im Schadensfall gilt der
              Versicherungsanspruch des Kunden als an uns abgetreten. Der Kunde
              ist zur Verfügung über die unter dem Eigentumsvorbehalt stehenden
              Sachen nicht befugt. Bei Pfändungen oder Beschlagnahmen hat der
              Kunde uns unverzüglich schriftlich zu unterrichten und hat Dritte
              auf den Eigentumsvorbehalt der DECOIT GmbH & Co. KG unverzüglich
              in geeigneter Form hinzuweisen. Für den Fall, dass der Kunde
              dennoch die Liefergegenstände veräußert und wir dieses genehmigen
              sollten, tritt der Kunde uns bereits mit Vertragsabschluss alle
              Ansprüche gegen seine Abnehmer ab. Der Kunde ist verpflichtet, der
              DECOIT GmbH & Co. KG alle zur Geltendmachung dieser Rechte
              erforderlichen Informationen herauszugeben und die erforderlichen
              Mitwirkungshandlungen zu erbringen.
            </p>
          </li>

          <li className="list-group-item mb-3 pb-2">
            <h3 className="text-header"> Haftungsbeschränkung</h3>
            <p className="mb-1 text-justify">
              Die DECOIT GmbH & Co. KG haftet bei Vorsatz und grober
              Fahrlässigkeit nach den gesetzlichen Vorschriften. Bei leichter
              Fahrlässigkeit haften wir nur, wenn eine wesentliche
              Vertragspflicht (Kardinalspflicht) verletzt wird oder ein Fall des
              Verzugs oder der Unmöglichkeit vorliegt. Im Fall einer Haftung aus
              leichter Fahrlässigkeit wird diese Haftung auf solche Schäden
              begrenzt, die vorhersehbar bzw. typisch sind. Eine Haftung für das
              Fehlen garantierter Eigenschaften, wegen Arglist, für
              Personenschäden, Rechtsmängel, nach dem Produkthaftungsgesetz und
              dem Bundesdatenschutzgesetz bleibt unberührt. Im Falle einer
              Inanspruchnahme von uns aus Gewährleistung oder Haftung ist ein
              Mitverschulden des Kunden angemessen zu berücksichtigen,
              insbesondere bei unzureichenden Fehlermeldungen oder
              unzureichender Datensicherung. Unzureichende Datensicherung liegt
              insbesondere dann vor, wenn der Kunde es versäumt hat, durch
              angemessene, dem Stand der Technik entsprechende
              Sicherungsmaßnahmen gegen Einwirkungen von außen, insbesondere
              gegen Computerviren und sonstige Phänomene, die einzelne Daten
              oder einen gesamten Datenbestand gefährden können, Vorkehrungen zu
              treffen. Die Verjährungsfrist für nichtwesentliche
              Vertragsverletzungen wird auf zwei Jahre begrenzt.
            </p>
          </li>

          <li className="list-group-item mb-3 pb-2">
            <h3 className="text-header">
              {' '}
              Gewährleistungen für Hard- und Software
            </h3>
            <p className="mb-1 text-justify">
              {' '}
              Die DECOIT GmbH & Co. KG gewährleistet, dass Hardware nicht mit
              Mängeln behaftet ist, die den Wert oder die Tauglichkeit zu dem
              gewöhnlichen oder nach dem Vertrag vorausgesetzten Gebrauch
              aufheben oder mindern. Software wird der Kunde unmittelbar nach
              der Lieferung untersuchen und dem Verkäufer offensichtliche Fehler
              schriftlich unverzüglich mitteilen. Die Gewährleistungsfrist
              beträgt zwölf Monate und beginnt mit dem Tag der Ablieferung.
              Software muss hinsichtlich ihrer Funktionsweise im Wesentlichen
              der Programmbeschreibung im begleitenden Schriftmaterial
              entsprechen. Tritt ein Mangel auf, so sind in einer schriftlichen
              Mängelrüge der Mangel und seine Erscheinungsform so genau zu
              beschreiben, dass eine Überprüfung des Mangels (z.B. Vorlage der
              Fehlermeldungen) machbar ist und der Ausschluss eines
              Bedienungsfehlers (z.B. Angabe der Arbeitsschritte) möglich ist.
              Erweist sich die Mängelrüge als berechtigt, setzt der Kunde uns
              eine angemessene Frist zur Nacherfüllung. Wir können die
              Nacherfüllung verweigern, wenn sie nur mit unverhältnismäßigen
              Kosten durchführbar ist und keine erhebliche Nachteile für den
              Kunden mit sich bringen. Zur Durchführung der Nacherfüllung für
              denselben oder in direktem Zusammenhang stehenden Mangel stehen
              uns zwei Versuche innerhalb der vom Kunden gesetzten Frist zu.
              Nach dem zweiten fehlgeschlagenen Nacherfüllungsversuch kann der
              Kunde vom Vertrag zurücktreten oder mindern. Die Gewährleistung
              erlischt bei Reparaturversuchen des Käufers oder von ihm
              Beauftragter Dritter. Die Abtretung von Gewährleistungsansprüchen
              ist ausgeschlossen. Der Rücktritt wegen eines unerheblichen
              Mangels ist ausgeschlossen. Hat der Kunde uns wegen Gewährleistung
              in Anspruch genommen, und stellt sich heraus, dass entweder kein
              Mangel vorhanden ist oder der geltend gemachte Mangel uns nicht
              zur Gewährleistung verpflichtet, so hat der Kunde, sofern er die
              Inanspruchnahme der DECOIT GmbH & Co. KG grob fahrlässig oder
              vorsätzlich zu vertreten hat, allen uns entstandenen Aufwand zu
              ersetzen. Keine Haftung wird dafür übernommen, dass die Software
              für die Zwecke des Kunden geeignet ist und mit beim Anwender
              vorhandener Software zusammenarbeitet. Die Lieferung von
              Handbüchern und Dokumentationen über das mit der Software
              ausgelieferte Schriftmaterial/Programmbeschreibung und die in die
              Software implementierte Benutzerführung und/oder Online-Hilfe
              hinaus, oder eine Einweisung, wird nur dann geschuldet, wenn dies
              ausdrücklich schriftlich zwischen den Parteien vereinbart worden
              ist.
            </p>
          </li>
          <li className="list-group-item mb-3 pb-2">
            <h3 className="text-header">
              {' '}
              Vertraulichkeit, Beweisklausel und Schutzrechte
            </h3>
            <p className="mb-1 text-justify">
              Die DECOIT GmbH & Co. KG und der Kunde verpflichten sich
              gegenseitig, alle Geschäfts- und Betriebsgeheimnisse der anderen
              Seite unbefristet geheim zu halten und nicht an Dritte
              weiterzugeben oder in irgendeiner Weise zu verwerten. Die
              Unterlagen, Zeichnungen und andere Informationen, die der andere
              Vertragspartner aufgrund der Geschäftsbeziehung erhält, darf
              dieser nur im Rahmen des jeweiligen Vertragszweckes nutzen. Der
              Kunde ist verpflichtet, alle vertraulichen Informationen mit dem
              Vermerk „Vertraulich“ zu versehen. Daten, die in elektronischen
              Registern oder sonst in elektronischer Form bei der uns
              gespeichert sind, gelten als zulässiges Beweismittel für den
              Nachweis von Datenübertragungen, Verträgen und ausgeführten
              Zahlungen zwischen den Parteien. Ohne ausdrückliche Genehmigung
              der DECOIT GmbH & Co. KG ist es dem Käufer nicht gestattet, die
              von uns erworbene Ware in Länder außerhalb der EG zu exportieren.
              Daneben hat der Käufer sämtliche einschlägige Exportbestimmungen,
              insbesondere diejenigen nach der Außenwirtschaftsverordnung sowie
              gegebenenfalls Regelungen nach US-Recht, zu beachten.
            </p>
          </li>

          <li className="list-group-item mb-3 pb-2">
            <h3 className="text-header"> Abnahme</h3>
            <p className="mb-1 text-justify">
              Nach Installation, Konzeption und Prüfung teilen wir dem Kunden
              schriftlich mit, dass das gelieferte Produkt (Hardware, Software,
              Dienstleistung) in vollem Umfang funktionsfähig bzw. vollständig
              ist, und fordert den Kunden zur Abnahme auf. Der Kunde kann
              daraufhin das Produkt prüfen. Für den Fall, dass Abnahmefähigkeit
              vorliegt, wird der Kunde unverzüglich, spätestens jedoch binnen 30
              Tagen nach der schriftlichen Mitteilung der DECOIT GmbH & Co. KG,
              die Abnahme schriftlich gegenüber uns erklären. Erfolgt innerhalb
              dieser Frist keine Abnahme durch den Kunden, so gilt die Abnahme
              dennoch als vorgenommen. Maßgeblich für den Fristanlauf ist der
              Zugang des Schreibens beim Kunden. Zahlt der Kunde nach
              Inbetriebnahme des Produkts die Vergütung ohne Beanstandung, so
              steht dies einer Abnahme des Produkts gleich. Die Abnahme kann
              wegen Vorliegen von unwesentlichen Mängeln nicht verweigert
              werden. Der Kunde trägt Sorge dafür, dass die
              datenschutzrechtlichen Voraussetzungen für den Einsatz der
              Lieferung am Einsatzort erfüllt sind. Fehlende
              datenschutzrechtliche Voraussetzungen berechtigen den Kunden nicht
              zur Verweigerung der Abnahme.
            </p>
          </li>

          <li className="list-group-item mb-3 pb-2">
            <h3 className="text-header">
              {' '}
              Eigentum, Urheberrechte und Quellcode
            </h3>
            <p className="mb-1 text-justify">
              Die dem Kunden überlassene Software bzw. Konzepte verbleiben
              einschließlich der gesamten Dokumentation in unserem Eigentum. Die
              DECOIT GmbH & Co. KG bleibt Inhaberin aller Urheber- und
              Nutzungsrechte an den dem Kunden überlassenen Programmen bzw.
              Konzepten einschließlich des jeweils dazugehörenden
              Dokumentationsmaterials, auch wenn der Kunde sie verändert oder
              mit seinen eigenen Programmen/Textpassagen und/oder denjenigen
              eines Dritten verbindet. Bei derartigen Änderungen oder
              Verbindungen sowie bei der Erstellung von Kopien bringt der Kunde
              einen entsprechenden Urhebervermerk an. Änderungen und
              Erweiterungen des Programmcodes, die auf Wunsch und Rechnung des
              Kunden durchgeführt werden, gehen in das Eigentum der DECOIT GmbH
              & Co. KG über und können anderen Kunden nach Zustimmung des Kunden
              zur Verfügung gestellt werden. Die Nutzungsrechte für die
              Programmverbesserungen werden an uns abgetreten. Wir nehmen die
              Abtretung hiermit an. Eine Änderung des Programmcodes durch den
              Kunden ist nur nach vorheriger schriftlicher Zustimmung durch uns
              zulässig. Die Zustimmung darf nur aus wichtigem Grund verweigert
              werden. Von solchen Änderungen stellt der Kunde uns eine Kopie der
              Änderung auf einem Datenträger oder in gedruckter Form zusammen
              mit allen notwendigen Informationen zur Verfügung. Eine Verwertung
              der geänderten Programmversion bedarf der Zustimmung des Kunden.
              Werden vom Kunden oder von Dritten geänderte Programme oder
              andere, nicht von uns bezogene Programme eingesetzt und dadurch
              die Funktion des Systems beeinträchtigt, so sind wir für
              entstehende Schäden nicht haftbar. Eine Herausgabe des Quellcodes
              bedarf einer expliziten schriftlichen Vereinbarung über Art und
              Umfang der Herausgabe sowie einer Zusatzvergütung.
            </p>
          </li>

          <li className="list-group-item mb-3 pb-2">
            <h3 className="text-header">
              {' '}
              Rechtswahl, Erfüllungsort und Gerichtsstand
            </h3>
            <p className="mb-1 text-justify">
              Unsere gesamten Geschäftsbeziehungen mit unseren Kunden
              unterliegen ausschließlich dem Recht der Bundesrepublik
              Deutschland. Verweist dieses Recht auf ausländische
              Rechtsordnungen, sind solche Verweisungen unwirksam. Die Anwendung
              des UN-Kaufrechtes (UNCITRAL) wird ausdrücklich ausgeschlossen.
              Erfüllungsort für Lieferungen und Leistungen ist diejenige Stelle,
              die vertraglich als Erfüllungsadresse vereinbart ist, im Zweifel
              Bremen. Erfüllungsort für Zahlungen ist der Ort der in der
              Rechnung angegebenen Zahlstelle. Gerichtsstand ist, soweit
              gesetzlich zulässig, der Sitz der DECOIT GmbH & Co. KG (Bremen) in
              der Bundesrepublik Deutschland. Ist Vertragspartner von uns kein
              Vollkaufmann, gilt die gesetzliche Regelung.
            </p>
          </li>

          <li className="list-group-item mb-3 pb-2">
            <h3 className="text-header"> Salvatorische Klausel</h3>
            <p className="mb-1 text-justify">
              Sollten einzelne Klauseln dieser Vertragsbedingungen oder daneben
              etwa abgeschlossener individueller Vereinbarungen ganz oder
              teilweise ungültig sein, berührt das die Wirksamkeit der übrigen
              Klauseln nicht. Die unwirksame Klausel wird durch eine andere
              ersetzt, die dem wirtschaftlichen Zweck der unwirksamen Regelung
              am nächsten kommt und ihrerseits wirksam ist.
            </p>
          </li>
        </ul>
      </div>
    </Section>
  </>
);

export default Agb;
