import wesermann from 'assets/img/logos/b&w/wesemannn.png';
import hwb from 'assets/img/logos/b&w/logo_hwb.png';
import ks from 'assets/img/logos/b&w/ksGroup.png';

export default [
  {
    description:
      'Die K&S Gruppe ist ein Familienunternehmen mit über 4.000 Mitarbeitern in der Pflege- und Sozialwirtschaft. Die Gruppe ist eine Muttergesellschaft mit zahlreichen Töchtern: K&S Seniorenresidenzen, K&S Ambulante Pflege, K&S Tagespflege, K&S Wohnen mit Service, CARE, CARe Mobil, ELBE-Bau und HUMAN-CARE. Diese sind mit dem Schwerpunkt der stationären und ambulanten Pflege bundesweit aktiv, aber auch in den Bereichen Bauplanung und Betreuung, Facility Management, Sozialarbeit, Hauswirtschaft und Catering zuhause. Die K&S Gruppe setzt die ScanBox nach einer ausgiebigen Testphase ein, um den wachsendem Bedrohungspotential entgegenzuwirken und die zukünftigen Sicherheitsrichtlinien erfüllen zu können.',
    companyImage: ks,
    size: 100,
  },
  {
    description:
      'Das Wesemann® Netzwerk ist ein Verbund aus mittelständischen Unternehmen, der sich auf die Entwicklung und Herstellung anspruchsvoller Einrichtungssysteme spezialisiert hat. Wesemann® Laboreinrichtungen mit über 200 Mitarbeitern und mit Sitz in Syke produziert Laboreinrichtungssysteme und naturwissenschaftliche Fachraumeinrichtungen, aber auch Büroeinrichtungen sowie Arbeitsplatzsysteme für den Personen- und Produktschutz. Mit ScanBox® setzt die Wesemann® GmbH nun auf noch mehr Schutz ihrer eigenen IT-Infrastruktur gegen Angriffe von außen.',
    companyImage: wesermann,
    size: 150,
  },
  {
    description:
      'Das Abwasserunternehmen hanseWasser Bremen GmbH betreibt mit rund 400 Mitarbeiterinnen und Mitarbeitern das 2.300 Kilometer lange Bremer Kanalnetz und sichert auf zwei Kläranlagen einen wirtschaftlichen und umweltgerechten Reinigungsprozess für jährlich rund 50 Millionen Kubikmeter Abwasser aus Bremen, den Nachbargemeinden sowie für Industrie- und Gewerbekunden. Das KRITIS-Unternehmen war von Anfang an als assoziierter Partner im ScanBox-Projekt dabei und profitierte davon, dass der ScanBox-Prototyp in der eigenen Umgebung evaluiert und an hanseWasser-Bedürfnisse angepasst wurde',
    companyImage: hwb,
    size: 60,
  },
];
