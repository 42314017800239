import NavbarTop from 'components/navbar/top/NavbarTop';
import { Outlet } from 'react-router-dom';
import React from 'react';
import FooterStandard from 'components/pages/landing/FooterStandard';

export default function MainLayout() {
  return (
    <div className="app-container">
      <NavbarTop />
      <Outlet className="content" />
      <FooterStandard />
    </div>
  );
}
