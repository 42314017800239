import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import Section from 'components/common/Section';
import Slider from 'react-slick';
import { isIterableArray } from 'helpers/utils';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import testimonials from 'data/testimonial/testimonialList';

const TestimonialItem = ({ description, companyImage, size }) => {
  return (
    <div className="px-sm-0 px-md-1 px-lg-5 px-xl-5">
      <p className="fs-sm-9 fs-md-8  text-800 ">{description}</p>

      <img className="w-auto mx-auto" src={companyImage} alt="" height={size} />
    </div>
  );
};

const settings = {
  infinite: true,
  speed: 1500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 7000,
};

const Testimonial = () => (
  <Section id="referenzen" className="text-center bg-body-tertiary  pt-3 mt-4">
    <h2 className=" pt-3 mt-2  text-header fs-xs-5 fs-sm-5  fs-md-4 fs-lg-3 fs-xl-3  ">
      Referenzen{' '}
    </h2>
    {/*<p className=" my-1 mb-3 pb-4  pt-0 text-700 fs-lg-6 fs-xl-6  ">
      Entdecken Sie vertrauenswürdige Kundengeschichten
    </p>*/}

    <Row className="justify-content-center ms-1">
      <Col xs={10} lg={9} xl={9} className="mt-3">
        <Slider {...settings}>
          {isIterableArray(testimonials) &&
            testimonials.map((testimonial, index) => (
              <TestimonialItem {...testimonial} key={index} />
            ))}
        </Slider>
      </Col>
    </Row>
  </Section>
);

TestimonialItem.propTypes = {
  description: PropTypes.string.isRequired,
  companyImage: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
};

export default Testimonial;
