import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { useAppContext } from 'Main';
import MainLayout from 'layouts/MainLayout';
import Landing from 'components/pages/landing/Landing';
import Agb from 'components/pages/landing/Agb';
import Datenschutz from 'components/pages/landing/DatenSchutz';
import Impressum from 'components/pages/landing/Impressum';
import is from 'is_js';
import Error404 from 'components/errors/Error404';
import ScrollToTop from 'components/common/ScrollTop';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const App = () => {
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition },
  } = useAppContext();

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>ScanBox</title>
        <meta
          name="description"
          content="Ein benutzerfreundliches Tool unterstützt IT-Administratoren bei der effizienten Sicherheitsanalyse"
        />
        <meta property="og:title" content="ScanBox" />
      </Helmet>

      <Router basename={process.env.PUBLIC_URL}>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<MainLayout />}>
            <Route index element={<Landing />} />
            <Route path="Agb" element={<Agb />} />
            <Route path="Datenschutz" element={<Datenschutz />} />
            <Route path="Impressum" element={<Impressum />} />
            <Route path="*" element={<Error404 />} />
          </Route>
        </Routes>
        <ToastContainer
          closeButton={CloseButton}
          icon={false}
          position={toast.POSITION.TOP_RIGHT}
        />
      </Router>
    </HelmetProvider>
  );
};

export default App;
