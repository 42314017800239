import React from 'react';
import PropTypes from 'prop-types';

const Attribution = ({ link, text }) => {
  return (
    <p className="fs-11 pt-0 mt-0 ps-8 text-200">
      <a
        className="text-400"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {text}
      </a>
    </p>
  );
};

Attribution.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default Attribution;
