import React from 'react';
import App from './App';
import Main from './Main';
import 'helpers/initFA';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';

const container = document.getElementById('main');

if (container.hasChildNodes()) {
  hydrateRoot(
    container,
    <React.StrictMode>
      <Main>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </Main>
    </React.StrictMode>
  );
} else {
  // Use createRoot if the container is empty (initial client-side render)
  createRoot(container).render(
    <React.StrictMode>
      <Main>
        <HelmetProvider>
          <App />
        </HelmetProvider>
      </Main>
    </React.StrictMode>
  );
}
{
  /*import React from 'react';
  import { createRoot } from 'react-dom/client';
  import App from './App';
  import Main from './Main';
  import 'helpers/initFA';
  
  const container = document.getElementById('main');
  const root = createRoot(container);
  
  root.render(
    <React.StrictMode>
      <Main>
        <App />
      </Main>
    </React.StrictMode>
  );*/
}
