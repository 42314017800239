import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Row, Col, Image } from 'react-bootstrap';
import Attribution from './Attribution.js';

const Process = ({
  isFirst,
  title,
  description,
  image,
  reverse,
  attribution,
  children,
}) => {
  return (
    <Row
      className={classNames('flex-center mx-0 px-1 ps-5', {
        'mt-1': !isFirst,
        'mt-2': isFirst,
      })}
    >
      <Col
        lg={5}
        xl={4}
        className={classNames({ 'order-lg-2 mx-2': !reverse })}
      >
        <Image fluid className=" mx-4  px-0 w-75" src={image} alt="" />
        <Attribution link={attribution.link} text={attribution.text} />
      </Col>
      <Col lg={6} xl={4} className={classNames(' ', { 'order-lg-1': reverse })}>
        <h3 className="fs-xs-8 fs-sm-7  fs-md-6   fs-lg-7 fs-xl-6 fs-xxl-4 fw-medium text-700 pb-2">
          {title}
        </h3>
        <p className="fs-sm-9  fs-md-8 fs-lg-8 fs-xl-8 fs-xxl-7 text-justify">
          {description}
        </p>
        {children}
      </Col>
    </Row>
  );
};

Process.propTypes = {
  isFirst: PropTypes.bool,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  reverse: PropTypes.bool,
  attribution: PropTypes.shape({
    link: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.node,
};

export default Process;
