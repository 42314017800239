import React from 'react';
import dashboard from 'assets/img/laptop0.png';
import Section from 'components/common/Section';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Typewriter } from 'react-simple-typewriter';
import { Link as ScrollLink } from 'react-scroll';

const Hero = () => {
  return (
    <Section
      id="Startseite"
      className="py-0 overflow-hidden px-3  px-xl-6 mx-xl-1 px-lg-3 mt-5 pt-5 "
      data-bs-theme="tertiary"
      position="center bottom"
      overlay
    >
      <Row className="justify-content-center align-items-center pt-1 pt-lg-2 pb-lg-1 pb-xl-1 ms-1">
        <Col
          sm={12}
          md={12}
          lg={7}
          xl={5}
          className="pb-4 pb-xl-4 pt-3 text-xl-start text-xl-start text-center"
        >
          <h1 className="visually-hidden">
            Scanbox-Ihr zuverlässiges System zur Angriffserkennung
          </h1>
          <h1 className="fw-medium pb-2 fs-xl-3 fs-lg-4 fs-md-4 fs-sm-5 fs-xs-8 pt-sm-4 pt-lg-4  text-header">
            Ihr{' '}
            <Typewriter
              words={['einfaches', 'zuverlässiges', 'skalierbares']}
              loop={false}
              cursor
              cursorStyle="|"
              typeSpeed={100}
              deleteSpeed={70}
              delaySpeed={1000}
            />{' '}
            <br />
            System zur Angriffserkennung
          </h1>
          <p className="lead  opacity-85 fs-7 ">
            Ein benutzerfreundliches Tool unterstützt IT&#8209;Administratoren
            bei der effizienten Sicherheitsanalyse
          </p>
          <Button
            as={ScrollLink}
            variant="outline-primary"
            size="lg"
            className="border-2 rounded-pill mt-4 fs-9 py-2 btn-outline-scanbox"
            to="Features"
            smooth={true}
            duration={700}
          >
            Mehr über ScanBox erfahren!
          </Button>
        </Col>
        <Col md={12} lg={5} xl={7} className=" mt-4 mt-xl-0 ms-auto">
          <Link to="/" className="">
            <img src={dashboard} alt="" className="w-100 h-auto" />
          </Link>
        </Col>
      </Row>
    </Section>
  );
};

export default Hero;
