import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';

const SectionHeader = ({ title, subtitle, ...rest }) => {
  return (
    <Row className="justify-content-center text-center pt-0 mt-0" {...rest}>
      <Col lg={8} xl={7} xxl={6} className="col-xxl-6">
        <h2 className=" fs-xs-5 fs-sm-5  fs-md-4 fs-lg-3 fs-xl-3  text-header pt-2  ">
          {title}
        </h2>
        <p className="lead text-700 fs-sm-9 fs-md-8 pb-4">{subtitle}</p>
      </Col>
    </Row>
  );
};

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default SectionHeader;
